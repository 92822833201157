[
  {
      "constant": true,
      "inputs": [],
      "name": "name",
      "outputs": [
            {
                    "name": "",
                    "type": "bytes32"
                  }
          ],
      "payable": false,
      "stateMutability": "view",
      "type": "function"
  },
  {
      "constant": true,
      "inputs": [],
      "name": "symbol",
      "outputs": [
            {
                    "name": "",
                    "type": "bytes32"
                  }
          ],
      "payable": false,
      "stateMutability": "view",
      "type": "function"
    }
]
